import React from "react";
import group from "../images/ceed/group.jpg";
import Layout from "../components/layout";
import { Link } from "gatsby";

const CohortTwoPage = () => (
  <Layout>
    <section id="section-page2" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">Cap Cohort - II</h2>

              <ul className="list-unstyled list-inline">
                <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                <li className="list-inline-item"><a>/</a></li>
                <li className="list-inline-item"><a href="#" className="text-muted">Cohort - II</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="cohort-two-page" className="section about-page">

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">About CAP Cohort - II</h6>
              <h2 className="display-4">Promoting Entrepreneurship</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 cap-group">
            <img src={group} alt={"CAP-group"}/>
            <h6 className="image-title">Team CAP with Mr Ramanan Ramanathan (Mission Director Atal Innovation Mission - Niti
              Aayog - Govt. of India)</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>In the last few months due to COVID 19, students in the universities have come out with a lot of
              innovative ideas & inventions to build their startups around them. Due to this changed scenario &
              shrinkage of jobs, post COVID 19, lots of students want to opt for entrepreneurship. 7 companies have been
              formed by Chitkara University from the CEED ACCELERATOR PROGRAM Cohort-I in 2019-2020. Here is a golden
              chance for you to build your own Startup with CAP-Chitkara University’s most stellar initiative to promote
              entrepreneurship at campus in North India.</p>
            <p className="label">Launching CEED ACCELERATOR PROGRAM (CAP) Cohort-II</p>
            <p>With a core aim to build campus startups and market ready enterprises, CEED is coming up CAP Cohort-II.
              CAP Cohort-II is a one semester long online program with 6 credits, fully funded by Chitkara University.
              CAP Cohort-II started from 18th July, 2020. Faculty for this cohort comprises of Industry Entrepreneurs,
              Academicians and Angel Investors.The cohort is superwised by an accomplished by Chitkara University’s
              Startup Governing Council & Committtee.</p>
            <div>
              <p className="label">Broadly following 6 courses will be covered:</p>
              <ul>
                <li>
                  <span className="bold">Entrepreneurship and Opportunity: </span>
                  <span>Basic of entrepreneurship, Identify gaps, problem statements, market opportunity</span>
                </li>
                <li>
                  <span className="bold">Idea Generation & Idea Validation: </span>
                  <span>Ideation, idea generation, assessment, protection, product/ service to market journey</span>
                </li>
                <li>
                  <span className="bold">Consumer Research and Market Analysis </span>
                  <span>for entrepreneurs: customer survey, customer Persona Design, competition analysis, catching value proposition, data analysis </span>
                </li>
                <li>
                  <span className="bold">Prototype Development & Testing: </span>
                  <span>Tools-Paper & Digital Prototyping, Customer involvement & user testing</span>
                </li>
                <li>
                  <span className="bold">Finance for Entrepreneurs: </span>
                  <span>Preparing cost sheet, budget, balance sheet, forecasting</span>
                </li>
                <li>
                  <span className="bold">Raising Funds: </span>
                  <span>Incubator support, bootstrapping, seed money, angels investment & venture capital</span>
                </li>
                <li>
                  <span className="bold">New Venture Creation & Management: </span>
                  <span>Company formation, founder’s agreement, team formation & assigning role</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="actions">
              <a href="https://docs.google.com/forms/d/1_4r5ffeWq8SZeLr1wFW511KGsSjfe3fTuJ-Aj2vzIBU/closedform" className="btn btn-hero btn-rounded" target="_blank">Join The Program</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default CohortTwoPage;
